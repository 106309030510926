import React from 'react'
import { Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function registration() {
    const initialValues = {
        username: "",
        password: ""
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3).max(15).required(),
        password: Yup.string().min(4).max(20).required()
    });

    const onSubmit = (data) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'auth', data).then(() => {
            console.log(data);
        });
    };

  return (
    <div>
        <br/><br/><br/>
        <h1>Erstelle ein neues Konto</h1>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form className='formContainer'>
                <label>Benutzername: </label>
                <ErrorMessage name='username' component='span'/>
                <Field id='inputUsername' name='username' placeholder='(Ex. Livio)'/>

                <label>Passwort: </label>
                <ErrorMessage name='password' component='span'/>
                <Field id='inputPassword' name='password' type='password' placeholder='Your Password'/>

                <button type='submit'>Register</button>
            </Form>
        </Formik>


    </div>
  )
}

export default registration