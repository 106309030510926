import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import FlagIcon from '@mui/icons-material/Flag';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import QrCodeIcon from '@mui/icons-material/QrCode';

export const mainListItems = (
  <React.Fragment>
    <Link to="/" >
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Startseite" />
      </ListItemButton>
    </Link>

    <Link to="/resultate" >
    <ListItemButton>
      <ListItemIcon>
        <FlagIcon />
      </ListItemIcon>
      <ListItemText primary="Resultate" />
    </ListItemButton>
    </Link>

    <Link to="/Statistiken" >
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Statistiken" />
    </ListItemButton>
    </Link>


    <Link to="/einstellungen" >
    <ListItemButton>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Einstellungen" />
    </ListItemButton>
    </Link>
  </React.Fragment>
);

export const admin = (
  <>
  <Link to="/uploaddata" >
  <ListItemButton>
    <ListItemIcon>
      <UploadFileIcon />
    </ListItemIcon>
    <ListItemText primary="Upload Daten" />
  </ListItemButton>
  </Link>
  
  <Link to="/usermanagement" >
  <ListItemButton>
    <ListItemIcon>
      <GroupIcon />
    </ListItemIcon>
    <ListItemText primary="Benutzerverwaltung" />
  </ListItemButton>
  </Link>

  <Link to="/programme" >
  <ListItemButton>
    <ListItemIcon>
      <QrCodeIcon />
    </ListItemIcon>
    <ListItemText primary="Programme" />
  </ListItemButton>
  </Link>
</>

);

export const logout = (
  <React.Fragment>
    <br/>
    <Link to="/logout" >
      <ListItemButton>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText secondary="Abmelden"/>
      </ListItemButton>    
    </Link>
    <br/>
  </React.Fragment>
);