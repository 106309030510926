import React from 'react'
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

export default function UploadHistoryStatusCode(props) {

    switch(props.code) {
        case 1:
        case 2:
        case 3:
        case 5:
        case 11:
        case 20:
            return (
                <Tooltip title="Diese Datei wird gerade verarbeitet. Habe noch etwas Geduld!" placement="top" arrow>
                    <Chip label="In Bearbeitung" color="primary" size="small"/>
                </Tooltip> );
        case 30:
            return (
                <Tooltip title="Datei wurde erfolgeich Hochgeladen und Verarbeitet!" placement="top" arrow>
                    <Chip label="Erfolgreich" color="success" size="small"/>
                </Tooltip> );
        case 51:
            return (
                <Tooltip title="Datei wurde doppelt Hochgeladen!" placement="top" arrow>
                    <Chip label="Fehler" color="error" size="small"/>
                </Tooltip> );
        case 52:
            return (
                <Tooltip title="Fehler beim verarbeiten der Datei!" placement="top" arrow>
                    <Chip label="Fehler" color="error" size="small"/>
                </Tooltip> );
        default:
            return (
                <Tooltip title="Ein Unbekanter Fehler ist aufgetreten." placement="top" arrow>
                    <Chip label="Fehler" color="error" size="small"/>
                </Tooltip> );
      }
}
