import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import {useState} from 'react';
import { useEffect } from 'react';
import Axois from 'axios';
import TargetA10 from './TargetA10';
import TargetB from './TargetB';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

/* Arrow Icons */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
/* Custom CSS*/
import './result.css';

// Tabs Stuff Start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Tabs Stuff End
export default function Result() {

  const { schiessenid } = useParams();

  const [schiessendata, setSchiessendata] = useState([]);
  const [data, setData] = useState([]);
  const [stich, setStich] = useState([]);
  const [moreInfos, setMoreInfos] = useState(false);
  const [currenthover, setCurrenthover] = useState(5);
  const [date, setDate] = useState();
  const [total, setTotal] = useState();

  // Status Var, for whitch Target needs to be displayed
  const [scheibeA5, setScheibeA5] = useState(false);
  const [scheibeA10, setScheibeA10] = useState(false);
  const [scheibeA100, setScheibeA100] = useState(false);
  const [scheibeB, setScheibeB] = useState(false);

  // For Tab State on the right side
  const [tabvalue, setTabValue] = React.useState(4);


   useEffect(() => {
       
       Axois.post(process.env.REACT_APP_BACKEND_URL + 'getResults', {
           id: schiessenid,
         }, 
         {
             headers: {
               "x-access-token": localStorage.getItem("token"),
             },
         }).then((response) => {
            
            let sdate = new Date(response.data[0].Datum.substring(0,10));
            
            var options = {
              weekday: "long",
              year: "numeric",
              month: "2-digit",
              day: "numeric"
            }

            setDate(""+ sdate.toLocaleDateString("de", options));

            setSchiessendata(response.data);
            if (response.data[0].SiessenID) {
             
              // Get Shots
              Axois.post(process.env.REACT_APP_BACKEND_URL + 'getShots', {
                SiessenID: response.data[0].SiessenID,
              },
              {
                headers: {
                  "x-access-token": localStorage.getItem("token"),
                },
            }).then((response2) => {
              let Shots = response2.data;
              
            // GET STICH
            Axois.post(process.env.REACT_APP_BACKEND_URL + 'getstich', {
              StichID: response.data[0].fk_StichID,
            },
            {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
            }).then((response3) => {
              let StichData = JSON.parse(response3.data[0].Programm);

              // Maping Shots with Stich 
              // let Shots // (already exists) // Array with the Shots 
              let ShotIndex = 0;
              for (const key in StichData){
                if (StichData[key].Probe == true) {
                   
                  while (ShotIndex < Shots.length && Shots[ShotIndex].Feuerart === 0){
                    Shots[ShotIndex].Scheibe = StichData[key].scheibe;
                    ShotIndex++;
                  }
                } else {
                  if (StichData[key].Shots === 0) {
                    while (ShotIndex < Shots.length && Shots[ShotIndex].Feuerart === 1){
                      Shots[ShotIndex].Scheibe = StichData[key].scheibe;
                      ShotIndex++;
                    }
                  } else {
                    let NumberOfShots = StichData[key].Shots
                    for (let i = 1; ShotIndex < Shots.length && i <= NumberOfShots; i++){
                      Shots[ShotIndex].Scheibe = StichData[key].scheibe;
                      ShotIndex++;
                    }
                  }
                }
              }

              // Debug Informations - Console Logs
              /*
                console.log("StichData:")
                console.log(StichData)
                console.log("Shots:")
                console.log(Shots)
              */
              
              // Set Shots to State
              setData(Shots);

              // Check whitch Scheibe needts to be Displayed
              for (const key in StichData){
                if (StichData[key].scheibe === 'A5') {
                  setScheibeA5(true)
                  if(tabvalue === 4) {
                    setTabValue(0)
                  }
                } 
                if (StichData[key].scheibe === 'A10') {
                  setScheibeA10(true)
                  if(tabvalue === 4) {
                    setTabValue(1)
                  }
                } 
                if (StichData[key].scheibe === 'A100') {
                  setScheibeA100(true)  
                  if(tabvalue === 4) {
                    setTabValue(2)
                  }
                } 
                if (StichData[key].scheibe === 'B4') {
                  setScheibeB(true)
                  if(tabvalue === 4) {
                    setTabValue(3)
                  }
                }
              }


              //  console.log(StichArr)
              //  setStich(data);
            });
            // END Get Sich


            });
            }
         });

         // GET TOTAL OF RESULT
        // ID= schiessenid
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'gettotalres', {
          SiessenID: schiessenid,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
        },
        }).then((response) => {
          setTotal(response.data);
        });
        // END TOTAL OF RESULT
  }, []);

  // Calc here the Arrow of the shot
function calcShot(Xcord, Ycord){
  if (Xcord > 0 && Ycord > -50 && Ycord < +50) {
    return (<ArrowUpwardIcon/>);
  } 
  else if (Xcord < 0 && Ycord > -50 && Ycord < +50) {
    return (<ArrowDownwardIcon/>);
  }
  else if (Ycord > 0 && Xcord > -50 && Xcord < +50) {
    return (<ArrowForwardIcon/>);
  }
  else if (Ycord < 0 && Xcord > -50 && Xcord < +50) {
    return (<ArrowBackIcon/>);
  }
  else if (Ycord > 0 && Xcord > 0) {
    return (<NorthEastIcon/>);
  }
  else if (Ycord > 0 && Xcord < 0) {
    return (<SouthEastIcon/>);
  }
  else if (Ycord < 0 && Xcord < 0) {
    return (<SouthWestIcon/>);
  }
  else if (Ycord < 0 && Xcord > 0) {
    return (<NorthWestIcon/>);
  }
  else {
    return ('noArrow');
  }
}

function renderFeuerart(param){
    switch(param){
      case 0:
        return '(P)';
      case 1:
        return '(E)';
      case 2:
        return '(S)';
      }
  }
function moreinfos(event){
    setMoreInfos(event.target.checked);
  }

function getTime(TimeObject, indexnumber){
  if (indexnumber === 0) {
    return ("0s")
  } else {
    let oldTime = new Date('2000-01-01T' + data[indexnumber -1].Zeit + "Z");
    let currentTime = new Date('2000-01-01T' + data[indexnumber].Zeit + "Z");
    let DiiffSeconds = (currentTime - oldTime) / 1000;

    return ('+' + DiiffSeconds + 's');
  }

}

const handleChangeSwtich = (event) => {
  if (moreInfos){
    setMoreInfos(false)
  } else {
    setMoreInfos(true)
  }
};

const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
}

  if (schiessendata.error) {
    return (
    <>
    <div className='error'> 
    <Alert severity="error" variant='outlined'>
      <AlertTitle>Fehler</AlertTitle>
      <strong>{schiessendata.error}</strong>
    </Alert>
    </div>
    </>
    )
  }

  else if (schiessendata[0] && data[0]) {
    return(
      <>
      <div className='HeadSection' >
        <h1>{schiessendata[0].StichName}</h1>
        <strong>{date}</strong>
        <Grid container spacing={2} sx={{ mt: 2.5}}>
            <Grid item >
              <strong>Startzeit: </strong> {data[0].Zeit}
            </Grid>
            <Grid item >
              <strong>Endzeittzeit: </strong> {data[data.length -1].Zeit}
            </Grid>
            <Grid item>
              <strong>Scheibe Nr: </strong> {schiessendata[0].BahnNr}
            </Grid>  
          </Grid>
      </div>
        <div className='cont'> 
          <div className='divitem'>            
            <br/>
              <TableContainer component={Paper} sx={{ maxHeight: '40em' }}>
                <Table stickyHeader size="small" className='table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Richtung</TableCell>
                      <TableCell>Punkte</TableCell>
                      <TableCell>Zeit</TableCell>
                      <TableCell><strong>P</strong>robe / <strong>S</strong>erie / <strong>E</strong>inzelfeuer</TableCell>
                      { moreInfos ? <TableCell>Sekundarwertung</TableCell> : '' }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {
                        data.map((data, index) => (
                          <TableRow
                          className='HoverRow'
                          key={data.SchussID}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          onMouseEnter={() => setCurrenthover(index)}
                          onMouseLeave={() => setCurrenthover(-1)}
                          >
                            <TableCell align="right">{index + 1}</TableCell>
                            <TableCell align="center">{calcShot(data.Xcord, data.Ycord)}</TableCell>
                            <TableCell align="right">{data.Primarwertung}</TableCell>
                            <TableCell align="right">{getTime(data.Zeit, index)}</TableCell>
                            <TableCell align="center">{renderFeuerart(data.Feuerart)}</TableCell>
                            { moreInfos ? <TableCell align="left">{data.Sekundarwertung} </TableCell> : '' }
                          </TableRow>
                          
                        ))
                      }
                      <TableRow>
                        <TableCell><strong>Total</strong></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right"><strong>{total}</strong></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <FormGroup className='MoreInfoButton'>
                  <FormControlLabel control={<Switch checked={moreInfos} onChange={handleChangeSwtich}/>} label="weitere Informationen anzeigen" />
                </FormGroup>

          </div>

          <div className='divitem'>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs  value={tabvalue} onChange={handleTabChange} aria-label="basic tabs example">
              {scheibeA5 ?
                (
                  <Tab label="A5" {...a11yProps(0)}/>
                ) : (
                  <Tab label="A5" {...a11yProps(0)} 
                  sx={{
                    display: 'none'
                  }} />
                )
              }
              {scheibeA10 ?
                (
                  <Tab label="A10" {...a11yProps(1)} className="TabHidden"/>
                ) : (
                  <Tab label="A10" {...a11yProps(1)} className="TabHidden"
                  sx={{
                    display: 'none'
                  }}/>
                )
              }
              {scheibeA100 ?
                (
                  <Tab label="A100" {...a11yProps(2)} className="TabHidden"/>
                ) : (
                  <Tab label="A100" {...a11yProps(2)} className="TabHidden"
                  sx={{
                    display: 'none'
                  }}/>
                )
              }
              {scheibeB ?
                (
                  <Tab label="B4" {...a11yProps(3)} className="TabHidden"/>
                ) : (
                  <Tab label="B4" {...a11yProps(3)} className="TabHidden"
                  sx={{
                    display: 'none'
                  }}/>
                )
              }
              <Tab label="" {...a11yProps(3)} className="TabHidden"
                  sx={{
                    display: 'none'
                  }}/>
            </Tabs>
          </Box>
          <TabPanel value={tabvalue} index={0}>
            <TargetA10 data={data} hover={currenthover}/>
          </TabPanel>
          <TabPanel value={tabvalue} index={1}>
            <TargetA10 data={data} hover={currenthover}/>
          </TabPanel>
          <TabPanel value={tabvalue} index={2}>
            <TargetA10 data={data} hover={currenthover}/>
          </TabPanel>
          <TabPanel value={tabvalue} index={3}>
            <TargetB data={data} hover={currenthover}/>
          </TabPanel>
          <TabPanel value={tabvalue} index={4}></TabPanel>
          </div>
        </div>
      </>
    )
  }
}