import React from 'react';
import { useEffect } from 'react';
/* Custom CSS*/
import './target.css';

function TargetA10V2(props) {

    useEffect(()=>{

        const drawtargetA10 = (() => {
            var canvas = document.getElementById('targetA10');
            if (canvas.getContext) {
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                var X = canvas.width / 2;
                var Y = canvas.height / 2;
                
                // White Circle in Background
                ctx.fillStyle = "#ffffff";
                ctx.beginPath();
                var RR = canvas.width / 2;
                ctx.arc(X, Y, RR, 0, 2 * Math.PI, false);
                ctx.fill();

                // Back Sircle
                ctx.fillStyle = "#000000";
                ctx.beginPath();
                var RR = canvas.width / 20 * 6;
                ctx.arc(X, Y, RR, 0, 2 * Math.PI, false);
                ctx.fill();
        
                for (let R = 1; R < 11; R = R + 1) {
                    ctx.beginPath();
                    var RR = canvas.width / 20 * R;
                    ctx.arc(X, Y, RR, 0, 2 * Math.PI, false);
                    ctx.lineWidth = 1.25;
                    if (R < 6) {
                        ctx.strokeStyle = '#FFFFFF';
                    } else {
                        ctx.strokeStyle = '#000000';
                    }
                    ctx.stroke();
                }

                /* Draw Numbers */
                // Set Font Size
                if (canvas.width >= 1000) {
                    ctx.font = '50px Roboto';
                    var aligner = 15;
                } else if (canvas.width >= 750){
                    ctx.font = '27.5px Roboto';
                    var aligner = 10;
                } else if (canvas.width >= 500) {
                    ctx.font = '20px Roboto';
                    var aligner = 7;
                } else if (canvas.width >= 250){
                    ctx.font = '10px Roboto';
                    var aligner = 3;
                }else {
                    ctx.font = '7.5px Roboto';
                    var aligner = 2;
                }

                ctx.textAlign = "center";
                ctx.fillStyle = '#FFFFFF';
                ctx.fillText('10', canvas.width / 2, canvas.width / 2 + aligner);

                ctx.fillText('9', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 2) - aligner);
                ctx.fillText('8', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 3) - aligner);
                ctx.fillText('7', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 4) - aligner);
                ctx.fillText('6', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 5) - aligner);
                ctx.fillText('5', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 6) - aligner);
                ctx.fillStyle = '#000000';
                ctx.fillText('4', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 7) - aligner);
                ctx.fillText('3', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 8) - aligner);
                ctx.fillText('2', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 9) - aligner);
                ctx.fillText('1', canvas.width / 2, canvas.width / 2 + (canvas.width / 20 * 10) - aligner);

                ctx.fillStyle = '#FFFFFF';
                ctx.fillText('9', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 2) + 2.5 * aligner);
                ctx.fillText('8', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 3) + 2.5 * aligner);
                ctx.fillText('7', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 4) + 2.5 * aligner);
                ctx.fillText('6', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 5) + 2.5 * aligner);
                ctx.fillText('5', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 6) + 2.5 * aligner);
                ctx.fillStyle = '#000000';
                ctx.fillText('4', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 7) + 2.5 * aligner);
                ctx.fillText('3', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 8) + 2.5 * aligner);
                ctx.fillText('2', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 9) + 2.5 * aligner);
                ctx.fillText('1', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 10) + 2.5 * aligner);

                ctx.fillStyle = '#FFFFFF';
                ctx.fillText('9', canvas.width / 2 + (canvas.width / 20 * 2) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('8', canvas.width / 2 + (canvas.width / 20 * 3) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('7', canvas.width / 2 + (canvas.width / 20 * 4) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('6', canvas.width / 2 + (canvas.width / 20 * 5) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('5', canvas.width / 2 + (canvas.width / 20 * 6) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillStyle = '#000000';
                ctx.fillText('4', canvas.width / 2 + (canvas.width / 20 * 7) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('3', canvas.width / 2 + (canvas.width / 20 * 8) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('2', canvas.width / 2 + (canvas.width / 20 * 9) - 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('1', canvas.width / 2 + (canvas.width / 20 * 10) - 2 * aligner, canvas.width / 2 + aligner);

                ctx.fillStyle = '#FFFFFF';
                ctx.fillText('9', canvas.width / 2 - (canvas.width / 20 * 2) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('8', canvas.width / 2 - (canvas.width / 20 * 3) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('7', canvas.width / 2 - (canvas.width / 20 * 4) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('6', canvas.width / 2 - (canvas.width / 20 * 5) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('5', canvas.width / 2 - (canvas.width / 20 * 6) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillStyle = '#000000';
                ctx.fillText('4', canvas.width / 2 - (canvas.width / 20 * 7) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('3', canvas.width / 2 - (canvas.width / 20 * 8) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('2', canvas.width / 2 - (canvas.width / 20 * 9) + 2 * aligner, canvas.width / 2 + aligner);
                ctx.fillText('1', canvas.width / 2 - (canvas.width / 20 * 10) + 2 * aligner, canvas.width / 2 + aligner);
            }
        });

        drawtargetA10();

        
        const drawshotA10 = ((X, Y) => {
            var canvas = document.getElementById('targetA10');
            if (canvas.getContext) {
                var ctx = canvas.getContext('2d');
                ctx.save();
                ctx.translate( canvas.width / 2, canvas.height / 2 );
                
                if (props.hover == i) {
                    ctx.fillStyle = "#eb4034";
                } else {
                    ctx.fillStyle = "#347deb";
                }
                var ConX = (canvas.width / 1000) * X;
                var ConY = (canvas.width / 1000) * Y;
                ctx.beginPath();
                ctx.arc(ConY, ConX * -1, 5, 0, 2 * Math.PI, false);
                ctx.fill();
                ctx.restore();
            }
        });

        for(var i = 0; i < props.data.length; i++){
            if (props.data[i].Scheibe === "A10" || props.data[i].Scheibe === "A100") {
                drawshotA10(props.data[i].Xcord , props.data[i].Ycord);
            }
        } 
        
    });

    

  return (
    <div width="auto" height="auto" className='TargetDiv'>
            <canvas id="targetA10" width="575x" height="575px" className='TargetCanvas'></canvas>
    </div>
    
  )
}

export default TargetA10V2;