import React from 'react'
import { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadHistoryStatusCode from './UploadHistoryStatusCode';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Axois from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function DateFormatter(fdate){
    let sdate = new Date(fdate);
    let year = sdate.getFullYear();
    let month = sdate.getMonth();
    let day = sdate.getDate();

    return(new Date(year, month, day).toLocaleDateString("de"));
}


function GetDetails(props) {

    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'fileinfobyid', {
            FileID: props.FileID,
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
          },
          }).then((response) => {
    
            setData1(response.data.Data1[0])
            setData2(response.data.Data2)
            console.log(data2[0])
          });
    }, []);

    if (data1 != null && data2 != null) {
        return (
            <>
                <br/>
                <List dense={true}>
                    <ListItem>
                        Anzahl Resultate:&nbsp;&nbsp; <b> {data1.ResultCount}</b>
                    </ListItem>
                    <ListItem>
                        Anzahl Schützen:&nbsp;&nbsp; <b> {data1.UserCount}</b>
                    </ListItem>
                    <ListItem>
                        Anzahl Schüsse:&nbsp;&nbsp; <b> {data1.ShotsCount}</b>
                    </ListItem>
                </List>
                <h3>Resultate</h3>
                <p>Nachfolgend werden die ersten drei und letzten drei Resultate dieser Datei angezeigt.</p>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Schütze</TableCell>  
                                <TableCell>Scheibe Nr.</TableCell>                    
                                <TableCell>Startzeit</TableCell>
                                <TableCell>Endzeit</TableCell>  
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data2.map((row) => (
                            <TableRow>
                                <TableCell>{DateFormatter(row.Date)}</TableCell>
                                <TableCell>{row.Vorname} {row.Nachname}</TableCell>  
                                <TableCell>{row.BahnNr}</TableCell>                    
                                <TableCell>{row.StartTime}</TableCell>
                                <TableCell>{row.EndTime}</TableCell>  
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        ) 
    } else {
        return (
            <>  <br/><br/>
                <Box textAlign="center" sx={{ margin: 'auto' }}>
                    <CircularProgress />
                </Box>
            </>
        )
    }
    
}


function UploadHistoryOptionsDetails(props) {
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    return (
        <>
            <MenuItem onClick={handleClickOpen} disabled={props.setDisabled}>
                <ListAltIcon sx={{ marginRight: 1.25 }}/> Details</MenuItem>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Details"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <table width={"100%"}>
                            <tr>
                                <td>Hochgeladen am:</td>
                                <td>Datei Name:</td>
                                <td>Dateigrösse:</td>
                                <td>Status der Datei:</td>
                            </tr>
                            <tr>
                                <td><b>{DateFormatter(props.File.Created)}</b></td>
                                <td><b>{props.File.OriginalFileName}</b></td>
                                <td><b>{(props.File.FileSize / 1024).toFixed(1)} KB</b></td>
                                <td><UploadHistoryStatusCode code={props.File.StatusCode}/></td>                      
                            </tr>
                        </table>
                        <GetDetails FileID={props.File.FileID}/>
                        
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Schliessen
                    </Button>
                    </DialogActions>
                </Dialog>
        </>
    )
}


export { GetDetails, UploadHistoryOptionsDetails };