import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UploadHistoryOptionsChangeDate from './UploadHistoryOptionsChangeDate'
import {UploadHistoryOptionsDetails} from './UploadHistoryOptionsDetails'
import UploadHistoryOptionsDelete from './UploadHistoryOptionsDelete'


function isFileOptionDisabled(StatusCode, MinCode, MaxCode){
    let Code = parseInt(StatusCode)
    if (Code <= MinCode) {
        return true
    }
    else if (Code >= MaxCode) {
        return true
    }
    else {
        return false
    }
}

export default function UploadHistoryOptions(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                    <MoreVertIcon/>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={6}
            >
                <UploadHistoryOptionsDetails setDisabled={isFileOptionDisabled(props.File.StatusCode, 29, 1000)} File={props.File}/>
                <UploadHistoryOptionsChangeDate setDisabled={isFileOptionDisabled(props.File.StatusCode, 29, 50)} File={props.File}/>
                <UploadHistoryOptionsDelete setDisabled={isFileOptionDisabled(props.File.StatusCode, 29, 1000)} File={props.File}/>
            </Menu>
        </>
    )
}
