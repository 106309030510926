import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Axois from 'axios';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Alert from '@mui/material/Alert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./edidprogramm.css";
import Card from '@mui/material/Card';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import WYSIWYGEditor from './components/WYSIWYGEditor';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const Alertsnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function EdidProgramme() {

  const { stichid } = useParams();
  const [ error, setError] = useState(true);
  const [ title, setTitle] = useState();
  const [ refID, setRefID] = useState();
  const [ beschreibung, setBeschreibung] = useState();
  const [ programmList, setProgrammList] = useState([]);
  const [ indexCounter, setIndexCounter] = useState(1);
  const [ stichData, setStichData] = useState();
  const [opensnack, setOpensnack] = React.useState(false);
  const [notifyMessage, setnotifyMessage] = React.useState('');
  const [notifyColor, setnotifyColor] = React.useState('');

  const navigate = useNavigate();

  useEffect(() => {
    Axois.post(process.env.REACT_APP_BACKEND_URL + 'getprogrammbyid', {
      stichID: stichid
    }, 
    {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
    }).then((response) => {
      if (response.data.error) {
        setError(true)
      } else {
        setStichData(response.data[0])
        setTitle(response.data[0].StichName)
        setRefID(response.data[0].reference_number)
        setBeschreibung(response.data[0].stich_description)
        var programmListArray = []
        JSON.parse(response.data[0].Programm).forEach((element, index) => {
          element.index = index + 1
          programmListArray.push(element)
        });
        setProgrammList(programmListArray)
        setIndexCounter(programmList.length + 2)
        setError(false)
      }

    });
}, []);

// Option for Formatting Time in Render Output
const TimeOptions = {
  weekday: "long",
  year: "numeric",
  month: "2-digit",
  day: "numeric"
}

function handleChangeName(value){
  setTitle(value)
}

function handleChangeReferenzID(value){
  setRefID(value)
}

function handleChangeBeschreibung(value){
  setBeschreibung(value)
}

function handleOnDragEnd(result){
  if (!result.destination){ return }

  const items = Array.from(programmList);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  setProgrammList(items)
}

function handleChangeScheibe(indexToChange, value){
  const modifiedArray = [...programmList]
  modifiedArray[indexToChange].scheibe = value
  setProgrammList(modifiedArray)
}

function handleChangeProbe(indexToChange, value){
  const modifiedArray = [...programmList]
  modifiedArray[indexToChange].Probe = value
  setProgrammList(modifiedArray)
}

function handleChangeShots(indexToChange, value){
  const modifiedArray = [...programmList]
  modifiedArray[indexToChange].Shots = value
  setProgrammList(modifiedArray)
}

const handleRemoveItem = (indexToRemove) =>{
  var removedItem = programmList.filter((_, index) => index !== indexToRemove);
  setProgrammList(removedItem)
}

const handleCreateItem = () => {
 var newItem = [...programmList, {"Probe": false,"scheibe": "A10","Shots": 0, "index": (indexCounter + 1) }]
 setProgrammList(newItem)
 setIndexCounter(indexCounter + 1)

}

const handleSaveProgramm = () => {
  Axois.post(process.env.REACT_APP_BACKEND_URL + 'setprogrammbyid', {
    stichID: stichid,
    title: title,
    refID: refID,
    beschreibung: beschreibung,
    programmList: programmList
  }, 
  {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
  }).then((response) => {
    setnotifyMessage(response.data.success);
    setnotifyColor("success")
    setOpensnack(true);
  }).catch((error) => {
    setnotifyMessage(error.response.data.error)
    setnotifyColor("error")
    setOpensnack(true);
  });
}

const handleDeleteProgramm = () => {
  Axois.post(process.env.REACT_APP_BACKEND_URL + 'deleteprogrammbyid', {
    stichID: stichid,
  }, 
  {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
  }).then((response) => {
    setnotifyMessage(response.data.success);
    setnotifyColor("success")
    setOpensnack(true);
  }).catch((error) => {
    setnotifyMessage(error.response.data.error)
    setnotifyColor("error")
    setOpensnack(true);
  });
}

const handleGoBack = () => {
  navigate(-1)
}

// Snackbar  
const handleCloseSnack = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpensnack(false);
};

  if (error){
    return (
      <>
      <Container width="100%" sx={{ p: 2}}>
        <Alert icon={<FeedbackIcon fontSize="inherit" />} severity="warning">
          Keine Programm / Stich gefunden...
        </Alert>
      </Container>
      </>
    )
  } else {
    return (
      <>
        <Container width="100%" className='ProgContainer' sx={{ p: 3}}>
          <Typography variant="h5" sx={{mb: 2}}>
            Eigenschaften
          </Typography>
          <p className='ProgInformation' style={{ display: 'flex', alignItems: 'center' }}> <InfoIcon sx={{mr: 1, color:'#6E6E6E'}} /> Erstellt am {new Date(stichData.Created).toLocaleDateString("de", TimeOptions)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zuletzt Bearbeitet am {new Date(stichData.LastUpdated).toLocaleDateString("de", TimeOptions)}</p>
          
          <div className='SettingCard'>
            <p className='title' sx={{mt: '2em'}}>
              Stich-Name
            </p>
            <TextField id="outlined-basic" variant="outlined" value={title} fullWidth type="text" 
            onChange={(event) => handleChangeName(event.target.value)}/>
          </div>

          <div className='SettingCard'>
            <p className='title' sx={{pt: 2}}>
              StichID / Referenznummer
            </p>
            <TextField id="outlined-basic" variant="outlined" value={refID} fullWidth type="number" 
            onChange={(event) => handleChangeReferenzID(event.target.value)}/>
          </div>

          <div className='SettingCard'>
            <p className='title' sx={{pt: 2}}>
              Beschreibung
            </p>
            <WYSIWYGEditor
              value={beschreibung}
              type='medium'
              onChange={(event) => handleChangeBeschreibung(event)}
            />
            
          </div>



        </Container>


        <Container width="100%" sx={{ p: 3}} className='ProgContainer'>
          <Typography variant="h5" sx={{mb: 2}}>
          Programmierung
          </Typography>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <StrictModeDroppable droppableId='programms'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {programmList.map((item, index) => (
                    <Draggable key={item.index} draggableId={String(item.index)} index={index}>
                      {(provided) => (
                        <Card {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} sx={{ p: '0.5em', mb: '0.5em' }} className='DraggableCard'>
                          <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                            <Grid item>
                              <DragIndicatorIcon sx={{ color: '#1976d2' }}/>
                            </Grid>
                            <Grid item>
                              Scheibe: &nbsp;
                              <Select
                                value={item.scheibe}
                                sx={{ minWidth: 120 }} size="small"
                                onChange={(event) => handleChangeScheibe(index, event.target.value)}
                              >
                                <MenuItem value={'A5'}>A5</MenuItem>
                                <MenuItem value={'A10'}>A10</MenuItem>
                                <MenuItem value={'A100'}>A100</MenuItem>
                                <MenuItem value={'B4'}>B4</MenuItem>
                                <MenuItem value={'B100'}>B100</MenuItem>
                              </Select>
                            </Grid>
                            
                            <Grid item>
                              Probeschuss:
                              <Checkbox 
                                checked={item.Probe == true} 
                                onChange={(event) => handleChangeProbe(index, event.target.checked)}  
                              />
                            </Grid>

                            <Grid item>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                Anzahl Schüsse: &nbsp;&nbsp;
                                <TextField 
                                size="small" 
                                type="number"
                                value={item.Shots}
                                onChange={(event) => handleChangeShots(index, event.target.value)}
                                />
                              </div>
                            </Grid>
                            <Grid item>
                              <IconButton 
                                aria-label="delete" 
                                sx={{ '&:hover': { color: '#F22613' } }}
                                onClick={() => handleRemoveItem(index)}
                              >
                                <ClearIcon/>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                  </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
          </StrictModeDroppable>
        </DragDropContext>
        <Button 
          component="label" 
          variant="text" 
          startIcon={<AddIcon />} sx={{ mt: 3 }}
          onClick={() => handleCreateItem()}
        >
          Element Hinzufügen
        </Button>
        </Container>
        <Container width="100%" sx={{ p: 3}} className='ProgContainer Sticky2Bottom'>
        <Grid container spacing={4} sx={{p: 0}}>
          <Grid item>
            <Button variant='contained' size='large' startIcon={<DoneIcon/>} onClick={() => handleSaveProgramm()}>Spichern</Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' size='large' startIcon={<ArrowBackIcon/>} onClick={() => handleGoBack()}>Zurück</Button>
          </Grid>
          <Grid item>
            <Button variant='text' size='large' startIcon={<DeleteIcon/>} color="error" onClick={() => handleDeleteProgramm()}>Stich Löschen</Button>
          </Grid>
        </Grid>
        </Container>

        <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alertsnack onClose={handleCloseSnack} severity={notifyColor} sx={{ width: '100%' }}>
                {notifyMessage}
            </Alertsnack>
        </Snackbar>
      </>
    )
  }
}

export default EdidProgramme;