import React from 'react'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Navigate } from 'react-router-dom'
import Axois from 'axios';
import {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import "./alresults.css";

function DateFormatter(fdate){
    let sdate = new Date(fdate.substring(0,10));
            
    var options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "numeric"
    }

    return(sdate.toLocaleDateString("de", options));
}



export default function Results() {

    const [data, setData] = useState([]);
    const [filterDataStiche, setFilterDataStiche] = useState([]);
    const [filterDataYear, setFilterDataYear] = useState([]);
    const [filterDataMonth, setFilterDataMonth] = useState([]);
    const [isDataHere, setIsDataHere] = useState(false);
    const [filterYear, setFilterYear] = React.useState(null);
    const [filterMonth, setFilterMonth] = React.useState(null);
    const [filterStich, setFilterStich] = React.useState(null);
    const [hasMoreData, setHasMoreData] = React.useState(false);
    const [dataCursor, setDataCursor] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
    
          Axois.post(process.env.REACT_APP_BACKEND_URL + 'getresultsfilteroptions', {
            
          }, 
          {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
          }).then((response) => {
                setFilterDataStiche(response.data.stiche)
                setFilterDataYear(response.data.years)
                setFilterDataMonth(response.data.months)
          });
      }, []);


      useEffect(() => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getResultsv2', {
            cursor: 0,
            filterYear: filterYear,
            filterMonth: filterMonth,
            filterStich: filterStich
          }, 
          {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
          }).then((response) => {
                if(response.data.results) {
                    setIsDataHere(true);
                    setData(response.data.results);
                    setHasMoreData(response.data.HasMore)
                    setDataCursor(response.data.Cursor);
                } else {
                    setIsDataHere(false)
                    setData([]);
                }
                                
          });

      }, [filterYear, filterMonth, filterStich]);

      const elementRef = useRef(null)

    const fetchData = () => {

        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getResultsv2', {
            cursor: dataCursor,
            filterYear: filterYear,
            filterMonth: filterMonth,
            filterStich: filterStich
            }, 
            {
                headers: {
                "x-access-token": localStorage.getItem("token"),
                },
            }).then((response) => {
                if(response.data.results) {
                    setData(data.concat(response.data.results));
                    setHasMoreData(response.data.HasMore)
                    setDataCursor(response.data.Cursor)
                } else {
                    setIsDataHere(false)
                    setData([]);
                    
                }                
            });

      }

    function onIntersection(entries) {
        const firstEntry = entries[0]
        if (firstEntry.isIntersecting && hasMoreData){
            fetchData();
        }
    }


    const handleRowClick = (siessenID) => {
        navigate(`/resultat/${siessenID}`);
      }

    useEffect(()=>{
        const observer = new IntersectionObserver(onIntersection)
        if(observer && elementRef.current){
            observer.observe(elementRef.current)
        }

        return () => {
            if(observer) {
                observer.disconnect();
            }
        }
    }, [data])

if(isDataHere == true){
    return (
        <>
        <h2>Deine Resultate</h2>
        <Box sx={{ mb: 2.5 }}> 

            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterStich(newValue);
                }}
                multiple
                id="tags-outlined"
                options={filterDataStiche}
                getOptionLabel={(option) => option.StichName}
                filterSelectedOptions
                sx={{ mb: 1.5}}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Stich / Programm Filtern"
                    placeholder="..."
                />
                )}
            />
            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterYear(newValue);
                }}
                disablePortal
                id="combo-box-years"
                options={filterDataYear}
                getOptionLabel={(option) => option.years.toString()}
                sx={{ mb: 1.5}}
                renderInput={(params) => <TextField {...params} label="Jahr" />}
            />
            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterMonth(newValue);
                }}
                disablePortal
                id="combo-box-months"
                options={filterDataMonth}
                getOptionLabel={(option) => option.months}
                sx={{ mb: 1}}
                renderInput={(params) => <TextField {...params} label="Monat" />}
            />       
        </Box>


            <TableContainer
            sx={{ borderRadius: '3px'}}
            className='TableContainer'
            >
                <Table aria-label="Deine Resultate">
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Stich / Programm</TableCell>
                            <TableCell>Resultat</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((item, index) => {
                        return (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => handleRowClick(item.SiessenID)}
                                className={"HoverRow"}
                            >
                                <TableCell>{DateFormatter(item.Datum)}</TableCell>
                                <TableCell>{item.StichName}</TableCell>
                                <TableCell>{item.Total}</TableCell>
                                <TableCell>
                                    <KeyboardArrowRightIcon sx={{ fontSize: 25, color: '#1976d2'}}/>   
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br/><br/>
            <div className='statusmessagecontainer'>
                {hasMoreData 
                    ? <span className="loader" ref={elementRef}></span>
                    : <p><b> { data.length } </b> Resultate gefunden</p>
                }
               
            </div>
         
        </>
      )
} else {
    return (
        <>
            <h2>Deine letzen Resultate</h2>
            <Box sx={{ mb: 2.5 }}> 

            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterStich(newValue);
                }}
                multiple
                id="tags-outlined"
                options={filterDataStiche}
                getOptionLabel={(option) => option.StichName}
                filterSelectedOptions
                sx={{ mb: 1.5}}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Stich / Programm Filtern"
                    placeholder="..."
                />
                )}
            />
            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterYear(newValue);
                }}
                disablePortal
                id="combo-box-years"
                options={filterDataYear}
                getOptionLabel={(option) => option.years.toString()}
                sx={{ mb: 1.5}}
                renderInput={(params) => <TextField {...params} label="Jahr" />}
            />
            <Autocomplete
                
                onChange={(event, newValue) => {
                    setFilterMonth(newValue);
                }}
                disablePortal
                id="combo-box-months"
                options={filterDataMonth}
                getOptionLabel={(option) => option.months}
                sx={{ mb: 1}}
                renderInput={(params) => <TextField {...params} label="Monat" />}
            />       
        </Box>
            <Alert icon={<FeedbackIcon fontSize="inherit" />} severity="warning">
            Keine Resultate gefunden...
            </Alert>
        </>
    )
}




}