import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Logout() {

    let navigate = useNavigate();

    useEffect(() => {
        
        localStorage.clear();
        localStorage.setItem('auth', false);
        navigate("/login", { replace: true });
    })

  return (
    <></>
  )
}

export default Logout