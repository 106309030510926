import React, { useEffect, useState, useContext } from 'react';
import Axois from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import './loginstyle.css';
import logo from '../images/Logo.png';
import { Navigate, useNavigate } from 'react-router-dom'


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Kneubühler Livio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = (() => {
  let navigate = useNavigate();
  const [loginuserID, setloginUserID] = useState(0);
  const [loginkennwort, setloginKennwort] = useState('');
  const [error, setError] = useState(false);

  Axois.defaults.withCredentials = true;

  const handleSubmit = (event) => {
    setError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setloginUserID(parseInt(data.get('Lizenznummer')));
    setloginKennwort(data.get('password'));

    Axois.post(process.env.REACT_APP_BACKEND_URL + 'login', {
      UserID: loginuserID,
      Kennwort: loginkennwort
    }).then((response) => {
      if (response.data.error) {
        setError(true);
        document.getElementById("password").value = "";
      } else if (response.data.auth === true) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem('auth', true);
        localStorage.setItem('UserID', response.data.UserID);
        localStorage.setItem('Vorname', response.data.Vorname);
        localStorage.setItem('Nachname', response.data.Nachname);
        localStorage.setItem('VereinID', response.data.VereinID);
        localStorage.setItem('Rolle', response.data.Rolle);

        let sessionExpireDate = new Date(); 
        sessionExpireDate.setTime(sessionExpireDate.getTime() + 12 * 60 * 60 * 1000)

        localStorage.setItem('session-expire-date', sessionExpireDate);
        navigate("/", { replace: true });
      } else {
        
      }
      
    });
  };
  
  return (
    (localStorage.getItem('auth') === 'true') ? <Navigate to="/" replace={true} /> : (
      <Container maxWidth="xs" className='LoginForm'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo}></img>
          <Typography component="h1" variant="h5">
            Jetzt Anmelden und loslegen!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          { error ? (<Typography color="error" className='errorMessage'>Falsche Lizenznummer oder Kennwort!</Typography>) : ""}
            <TextField
              margin="normal"
              required
              fullWidth
              id="Lizenznummer"
              label="Lizenznummer"
              name="Lizenznummer"
              autoComplete="Lizenznummer"
              autoFocus
              onChange={(e) => {
                setloginUserID(parseInt(e.target.value));
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setloginKennwort(e.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Anmelden
            </Button>
          </Box>
        </Box>
      </Container>
  ));
})

export default Login;