import React from 'react';
import {useState} from 'react';
import { useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Axois from 'axios';
import UserSettingPassword from './components/UserSettingPassword'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function UserSettings() {

    const [datafirstname, setDatafirstname] = useState('');
    const [datalastname, setDatalastname] = useState('');
    const [datamail, setDatamail] = useState('');
    const [opensnack, setOpensnack] = React.useState(false);
    const [notifyMessage, setnotifyMessage] = React.useState('');
    const [notifyColor, setnotifyColor] = React.useState('');

    useEffect(() => {
        
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getUserSettings', {
            
          }, 
          {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
          }).then((response) => {
                let Vorname = String(response.data.Vorname)
                let Nachname = String(response.data.Nachname)
                let E_Mail_Adress = response.data.E_Mail_Adress
                setDatafirstname(Vorname);
                setDatalastname(Nachname);
                setDatamail(E_Mail_Adress);
          });
      }, []);

      const safeSettings = () => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'setUserSettings', {
            firstname: datafirstname,
            lastname: datalastname,
            email: datamail
        }, 
        {
            headers: {
            "x-access-token": localStorage.getItem("token"),
            },
        }).then((response) => {
            setnotifyMessage(response.data.success);
            setnotifyColor("success")
            setOpensnack(true);
          }).catch((error) => {
            setnotifyMessage(error.response.data.error)
            setnotifyColor("error")
            setOpensnack(true);
          });;
      }

      // Snackbar for API Response  
    const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
        setOpensnack(false);
    };
    const Alertsnack = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

  return (
    <Container>
    <h1>Benutzer Einstellungen</h1>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, },
                display: 'flex',
                flexDirection: 'column',
            }}
            noValidate
            autoComplete="off"
        >
            <TextField disabled id="filled-disabled" label="Schützen Lizenz"  defaultValue={ localStorage.getItem("UserID") } variant="outlined"/>
            <TextField id="firstname" label="Vorname" value={ datafirstname } variant="outlined" 
                onChange={(e) => {
                    setDatafirstname(e.target.value);
                }}
            />
            <TextField id="lastname" label="Nachname" value={ datalastname } variant="outlined" 
                onChange={(e) => {
                    setDatalastname(e.target.value);
                }}
            />
            <TextField id="emailadress" label="E-Mail Adresse" value={ datamail } variant="outlined" 
                onChange={(e) => {
                    setDatamail(e.target.value);
                }}
            />
            <Button variant="contained" size="medium" onClick={safeSettings} >
            Änderungen Speichern
            </Button>
        </Box>
        <br/>
        <hr/>
        <h1>Sicherheit</h1>
        <Box>
            <UserSettingPassword/>
        </Box>
        <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alertsnack onClose={handleCloseSnack} severity={notifyColor} sx={{ width: '100%' }}>
              {notifyMessage}
          </Alertsnack>
        </Snackbar>
    </Container>
  )
}
