import React from 'react';
import Container from '@mui/material/Container';
import TargetA10 from './TargetA10';
import TargetB from './TargetB';
import AllResults from './allresults';

function Resultate() {


  return (
    <div>
        <Container sx={{ width: 1 }}>
          <AllResults/>
        </Container>
    </div>
    
  )
}

export default Resultate;