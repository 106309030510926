import React, { useState } from 'react';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, BtnBulletList, BtnNumberedList, BtnUnderline } from 'react-simple-wysiwyg';
import "./WYSIWYGEditor.css";

const WYSIWYGEditor = (props) => {
    
    const [text, setText] = useState(props.value);

    function onChangeText(event) {
        setText(event.target.value);
        if (typeof props.onChange === 'function') {
            props.onChange(event.target.value);
        }
      }

    return (
        <>
            <EditorProvider>
                <Editor value={text} onChange={onChangeText} containerProps={{ style: { resize: 'vertical', height: '10em' } }}>
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </>
    );
};

export default WYSIWYGEditor;
