import React, { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom';


const ProtectedRoutes = () => {

    let currentDate = new Date();
    let logoutDate = new Date(localStorage.getItem('session-expire-date'))

    return (logoutDate > currentDate) ? <Outlet /> :  <Navigate to="/logout" replace={true}/>
}

export default ProtectedRoutes;