import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Axois from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alertsnack = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function UploadHistoryOptionsChangeDate(props) {
    
    const [open, setOpen] = React.useState(false);
    const [hours, setHours] = React.useState(0);
    const [days, setDays] = React.useState(0);
    const [opensnack, setOpensnack] = React.useState(false);
    const [notifyMessage, setnotifyMessage] = React.useState(undefined);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setHours(0);
        setDays(0);
        setOpen(false);
    };

    const getNotifyMessage = () => {
        if(notifyMessage){
            return ("Datum & Zeit wurden erfolgreich geändert.")
        } else {
            return ("Fehler beim ändern des Datums & der Zeit.")
        }
        
    }
    const getNotifyColor = () => {
        if(notifyMessage){
            return ("success")
        } else {
            return ("error")
        }
        
    }


    // Snackbar  
      const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpensnack(false);
      };

    const handleChancheDate = () => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'filechangedate', {
            FileID: props.File.FileID,
            Hours: hours,
            Days: days
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
          },
          }).then((response) => {
            console.log(response.data);
            setnotifyMessage(response.data.success);
            handleClose();
            setOpensnack(true);
          });
    }

    return (
        <>
            <MenuItem onClick={handleClickOpen} disabled={props.setDisabled}>
                <AccessTimeIcon sx={{ marginRight: 1.25 }}/> Datum anpassen</MenuItem>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Datum und Zeit korrigieren"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>Hier kann das Datum und die Zeit der Schiessen angepasst werden, welche in der CSV Datei vorhanden sind. </b><br/><br/>
                        <Alert severity="info">Um ein Jahr zurück zu korrigieren, <b>-365</b> eingeben. <br/>
                        Um einen Tag zurück zu korrigieren, <b>-24</b> Stunden eingeben. Um einen Tag nach vorne zu korrigieren, <b>24</b> Stunden eingeben.</Alert>
                        <br/>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <TextField 
                                id="standard-start-adornment" 
                                label="Korrektur Tage" 
                                variant="outlined" 
                                value={days}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                autoFocus
                                onChange={(e) => {
                                    setDays(e.target.value);
                                }}
                                sx={{ m: 1, width: '25ch' }}
                                type="number"
                            /> <br/><br/>
                            <TextField 
                                id="standard-start-adornment" 
                                label="Korrektur Stunden" 
                                variant="outlined" 
                                value={hours}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                autoFocus
                                onChange={(e) => {
                                    setHours(e.target.value);
                                }}
                                sx={{ m: 1, width: '25ch' }}
                                type="number"
                            />
                        </Box>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleChancheDate} color="error">
                        Ändern
                    </Button> 
                    <Button onClick={handleClose} autoFocus>
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alertsnack onClose={handleCloseSnack} severity={getNotifyColor()} sx={{ width: '100%' }}>
                        {getNotifyMessage()}
                    </Alertsnack>
                </Snackbar>
        </>
    )
}
