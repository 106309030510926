import React from 'react'
import Axois from 'axios';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadHistoryStatusCode from './UploadHistoryStatusCode';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function DateFormatter(fdate){
    let sdate = new Date(fdate);

    return(sdate.toLocaleString("de"));
}

function CheckControllUserID(UserID1, UserID2){
    if (UserID1 == UserID2){
        return false
    } else {
        return true
    }
}

const Alertsnack = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function UploadHistoryOptionsDelete(props) {
    
    let UserID = localStorage.getItem('UserID');

    const [open, setOpen] = React.useState(false);
    const [controlUserID, setControlUserID] = React.useState("");
    const [opensnack, setOpensnack] = React.useState(false);
    const [notifyMessage, setnotifyMessage] = React.useState(undefined);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setControlUserID("")
        setOpen(false);
    };

    const getNotifyMessage = () => {
        if(notifyMessage){
            return ("Datei wurde erfolgreich gelöscht.")
        } else {
            return ("Fehler beim löschen der Datei.")
        }
        
    }
    const getNotifyColor = () => {
        if(notifyMessage){
            return ("success")
        } else {
            return ("error")
        }
        
    }


    // Snackbar  
      const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpensnack(false);
      };


    // API Request to DELETE the File. 
    const handleDelete = () => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'filedeletebyid', {
            FileID: props.File.FileID,
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
          },
          }).then((response) => {
            setnotifyMessage(response.data.success);
            console.log(response.data);
            setControlUserID("");
            setOpen(false);
            setOpensnack(true);
          });
    }

    return (
        <>
            <MenuItem onClick={handleClickOpen} disabled={props.setDisabled}>
                <DeleteOutlineIcon sx={{ marginRight: 1.25 }}/> Löschen</MenuItem>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Datei Löschen"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Alert severity="error">Gelöschte Dateien können nicht wiederhergestellt werden! Es werden sämtliche Resultate gelöscht, welche in dieser Datei enthalten sind.</Alert><br/>
                        <b>Folgende Datei wird Gelöscht: </b><br/>
                        Hochgeladen am: {DateFormatter(props.File.Created)}<br/>
                        Datei Name:	{props.File.OriginalFileName}<br/>
                        Dateigrösse: {(props.File.FileSize / 1024).toFixed(1)} KB<br/>
                        Status der Datei: <UploadHistoryStatusCode code={props.File.StatusCode}/><br/><br/>
                        <b>Um den Löschvorgang zu bestätigtn, bitte deine Lizenznummer im Textfeld eingeben. 
                        Lizenznummer: {UserID}</b><br/><br/>
                        <TextField 
                            id="outlined-basic" 
                            label="Lizenznummer" 
                            variant="outlined" 
                            value={controlUserID}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            autoFocus
                            onChange={(e) => {
                                setControlUserID(e.target.value);
                            }}
                        />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDelete} color="error" disabled={CheckControllUserID(UserID, controlUserID)}>
                        Löschen
                    </Button> 
                    <Button onClick={handleClose}>
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>


                <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alertsnack onClose={handleCloseSnack} severity={getNotifyColor()} sx={{ width: '100%' }}>
                        {getNotifyMessage()}
                    </Alertsnack>
                </Snackbar>


        </>
    )
}
