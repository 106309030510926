import React from 'react';
import {useState} from 'react';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { autocompleteClasses } from '@mui/material';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./UploadDataCSS.css";
import Axios from 'axios';
import UploadHistory from './components/UploadHistory';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
const FormData = require('form-data');


function UploadData() {

    const [file, setFile] = useState('');
    const [isSelected, setIsSelected] = useState(false);
	const [isUploadSuccess, setIsUploadSuccess] = useState(false);
	const [isUploadError, setIsUploadError] = useState(false);
	const [statusMessage, setStatusMessage] = useState('');
	const [reloadCounter, setReloadCounter] = useState(1);


    const handleSubmission = () => {
        const form  = new FormData();
		form.append("FileData", file, file.name);
		console.log(file)
		Axios.post(process.env.REACT_APP_BACKEND_URL + 'uploadfile', form, {
            headers: {
				'Content-Type': 'multipart/form-data',
              	'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => {
			setStatusMessage(res.data.message);
			setIsUploadSuccess(true); 
			setIsUploadError(false);
			setFile(''); 
			setReloadCounter(reloadCounter+ 1)
			setIsSelected(false)
		}).catch(err => {
			setStatusMessage("Fehler beim Hochladen!");
			setIsUploadSuccess(false); 
			setIsUploadError(true);
			setFile(''); 
			setReloadCounter(reloadCounter+ 1)
			setIsSelected(false)
		});
		
	};

  return (
	<>
	<Container>
		
		<h2>Datei Hochladen</h2>
		<Container sx={{ bgcolor: "#ffffff", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", borderRadius: "3px", p: "2em"}}>

			<Box sx={{ minWidth: 120 }}>		
				<Collapse in={isUploadSuccess}>
							<Alert
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											isUploadSuccess(false);
										}}
									>
									<CloseIcon fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								Datei wurde erfolgreich hochgeladen!
							</Alert>
						</Collapse>

						<Collapse in={isUploadError}>
							<Alert severity="error"
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											isUploadError(false);
										}}
									>
									<CloseIcon fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								{statusMessage}
							</Alert>
						</Collapse>


					<input id="UploadFile" type="file" name="FileData" accept=".csv" onChange={event => {
						const file = event.target.files[0];
						setFile(file);
						setIsSelected(true);
						setIsUploadSuccess(false);
						setIsUploadError(false);
					}
					
						

					} className="inputData"/>
					<label for="UploadFile">Datei Auswählen</label>
					
					<br/>
					<span id="UploadSpan">

						{isSelected ? (
							<>
								<div className="InfoDiv">
									<h3>Ausgewählte Datei</h3>
									<p>Dateiname: {file.name}</p>
									<p>Dateiformat: {file.type}</p>
									<p>Dateigrösse in Bytes: {file.size}</p>
									<p>
										Zuletzt Bearbeitet:{' '}
										{file.lastModifiedDate.toLocaleDateString()}
									</p>
								</div>
								<Box component="form">
									<div>
										<Button variant="contained" onClick={handleSubmission}> <CloudUploadIcon fontSize="medium" className='iconSpaceRight'/> Hochladen</Button >
									</div>			
								</Box>
							</>
							) : (
								<p className='placeHolderText'><br/>Keine Datei ausgewählt!</p>
						)}

					</span>

			</Box>
			<br/>

		</Container>
		<br/>
		<h2>Verlauf hochgeladener Dateien</h2>
		<UploadHistory key={reloadCounter}/>
		<br/><br/>
	</Container>
		
	
	</>
  );
}

export default UploadData;