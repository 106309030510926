import React from 'react';
import { useNavigate } from 'react-router-dom';
import Axois from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


function ValidateInput(StichID, Name) {
    if ((Name.length > 2) && (StichID.length > 0)){
        return false;
    }
    return true; 
}

const Alertsnack = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddProgrammButton() {

    const [ openDialog, setOpenDialog ] = React.useState(false);
    const [ stichID, setStichID ] = React.useState('');
    const [ name, setName ] = React.useState('');
    const [opensnack, setOpensnack] = React.useState(false);
    const [notifyMessage, setnotifyMessage] = React.useState('');
    const [notifyColor, setnotifyColor] = React.useState('');

    const navigate = useNavigate();


    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleCreateStich = () => {
        
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'createnewprogramm', {
            StichID: stichID,
            Name: name
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
          },
          }).then((response) => {
            navigate('./' + response.data.StichID)
          }).catch((error) => {
            console.log("Test ERROR")
            setnotifyMessage(error.response.data.error)
            setnotifyColor("error")
            setOpensnack(true);
          });
      };

// Snackbar  
const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpensnack(false);
};

  return (
    <>
        <Button variant="contained" size="medium" onClick={handleClickOpenDialog}>
            Stich / Programm hinzufügen
        </Button>

        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Stich Hinzufügen
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                Bitte gib die StichID und der Name des Stichs ein. Die StichID muss zwingend eindeutig sein!
            </DialogContentText>
            <br/>
                <b>StichID (Referenznummer):</b> <br/>
                <TextField 
                    type="number"
                    sx={{width: '100%'}}
                    value={stichID}
                    onChange={(event) => setStichID(event.target.value)}
                />
                <br/><br/>
                <b>Name:</b> <br/>
                <TextField 
                    type="text"
                    sx={{width: '100%'}}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreateStich} disabled={ValidateInput(stichID, name)}>
                    Weiter
                </Button>
                <Button onClick={handleCloseDialog} color='inherit'>
                    Abbrehcen
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alertsnack onClose={handleCloseSnack} severity={notifyColor} sx={{ width: '100%' }}>
                {notifyMessage}
            </Alertsnack>
        </Snackbar>

    </>
  )
}
