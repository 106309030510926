import React from 'react'
import {useState, useEffect} from 'react';
import Axois from 'axios';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

/* Import Icons */
import svgShots from './../../images/bullet-free-svgrepo-com.svg';
import svgDays from './../../images/calendar-days-svgrepo-com.svg';
import svgResults from './../../images/paper-roll-svgrepo-com.svg';
import svgProgs from './../../images/outcomes-svgrepo-com.svg';

/* Import Custom CSS*/
import './StatsBasic.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

export default function StatsBasic(pros) {

    const [basicStats, setBasicStats] = useState([]);
    const [loadingstate, setloadingstate] = useState(true);

    useEffect(() => {
        
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getbasicstats', {
            year: pros.year,
          }, 
          {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
          }).then((response) => {
                if(response.data[0].StatsShots){
                    setBasicStats(response.data[0])
                    setloadingstate(false);
                }
          });
      }, []);


      if(loadingstate){
        return(
            <>
                  loading...
            </>

        )
      }else{
        return (
            <>
            <div className="row">
                <div className="column">
                    <div className="cardstats">
                        <img src={svgShots} className='svgicon'/><br/> 
                        <b>{basicStats.StatsShots}</b>
                        <p>Schüsse geschossen</p>
                    </div>
                </div>

                <div className="column">
                    <div className="cardstats">
                        <img src={svgResults} className='svgicon'/><br/> 
                        <b>{basicStats.StatsResults}</b>
                        <p>verschiedene Resultate</p>
                    </div>
                </div>

                <div className="column">
                    <div className="cardstats">
                        <img src={svgProgs} className='svgicon'/><br/> 
                        <b>{basicStats.StatsProgs}</b>
                        <p>verschiedene Stiche</p>
                    </div>
                </div>

                <div className="column">
                    <div className="cardstats">
                        <img src={svgDays} className='svgicon'/><br/> 
                        <b>{basicStats.StatsDays}</b>
                        <p>Schiesstage</p>
                    </div>
                </div>
            </div>
            </>
          )
      }
  
}
