import React from 'react'
import Container from '@mui/material/Container';
import StatsBasic from './components/StatsBasic';
import StatsPerformance from './components/StatsPerformance'
import {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Axois from 'axios';
import TextField from '@mui/material/TextField';

function StatsPage() {
    
  const [filterDataStiche, setFilterDataStiche] = useState([]);
  const [filterDataYear, setFilterDataYear] = useState([]);
  const [filterDataMonth, setFilterDataMonth] = useState([]);
  const [filterYear, setFilterYear] = React.useState();

  useEffect(() => {
    
    Axois.post(process.env.REACT_APP_BACKEND_URL + 'getresultsfilteroptions', {
      
    }, 
    {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
    }).then((response) => {
          setFilterYear(response.data.years[response.data.years.length -1])
          setFilterDataStiche(response.data.stiche)
          setFilterDataYear(response.data.years)
          setFilterDataMonth(response.data.months)
    });
}, []);
  

  return (
    <Container width="100%" sx={{ mt: 5 , mb:3}}>
      <Autocomplete
        onChange={(event, newValue) => {
            setFilterYear(newValue);
        }}
        disablePortal
        id="combo-box-years"
        options={filterDataYear}
        getOptionLabel={(option) => option.years.toString()}
        sx={{ mb: 1.5}}
        renderInput={(params) => <TextField {...params} label="Wähle ein Jahr aus..." />}
      />
      {filterYear && <h2>📈 Statistiken für das Jahr {filterYear.years}</h2>}
      {filterYear && <StatsBasic year={filterYear.years} key={filterYear.years}/>}
        <br/><br/>
      {filterYear && <StatsPerformance year={filterYear.years} key={filterYear.years + 1}/>}

    </Container>
  )
}

export default StatsPage;
