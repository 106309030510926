import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Axois from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';

function CheckPW(PW1, PW2){
    let PWStrenght = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    if (PW1 == PW2){
        if (PWStrenght.test(PW2)){
            return false
        }
        else {
            return true
        }
    } else {
        return true
    }
}

const Alertsnack = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function UserSettingPassword(props) {
    
    const [open, setOpen] = React.useState(false);
    const [vaildPW, setValidPW] = React.useState(false);
    const [currentPW, setCurrentPW] = React.useState('');
    const [newPWone, setNewPWone] = React.useState('');
    const [newPWtwo, setNewPWtwo] = React.useState('');
    const [opensnack, setOpensnack] = React.useState(false);
    const [notifyMessage, setnotifyMessage] = React.useState('');
    const [notifyColor, setnotifyColor] = React.useState('');

    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [showPassword3, setShowPassword3] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleClickShowPassword3 = () => setShowPassword3((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setCurrentPW('');
        setNewPWone('');
        setNewPWtwo('');
        setOpen(false);
    };

    // Snackbar  
      const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpensnack(false);
      };

    const handleChanchePasswort = () => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'setnewpasswortuser', {
            OldPW: currentPW,
            NewPW: newPWone
          },
          {
            headers: {
              "x-access-token": localStorage.getItem("token"),
          },
          }).then((response) => {
            setnotifyMessage(response.data.success);
            setnotifyColor("success")
            handleClose();
            setOpensnack(true);
          }).catch((error) => {
            setnotifyMessage(error.response.data.error)
            setnotifyColor("error")
            handleClose();
            setOpensnack(true);
          });
    }

    return (
        <>

            <Button variant="outlined" size="medium" onClick={handleClickOpen}>
                Passwort Ändern
            </Button>




                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Kennwort Ändern"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    
                    <p>
                        Ihr neues Kennwort muss folgende Anforderungen erfüllen: <br/>
                        - Mindestens <b>8 Zeichen</b><br/>
                        - Mindestens ein <b>Buchstabe von 'a' bis 'Z'</b> enthalten<br/>
                        - Mindestens eine <b>Zahl von 0 bis 9</b> enthalten<br/>
                        - Mindestens ein <b>Sonderzeichen +"*%&/()=?!$</b> einthalten<br/>
                    </p><br/>
                    <InputLabel htmlFor="outlined-adornment-password">Aktuelles Kennwort</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword1 ? 'text' : 'password'}
                        value={currentPW}
                        onChange={(e) => {
                            setCurrentPW(e.target.value);
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    <br/><br/>
                    <InputLabel htmlFor="outlined-adornment-password">Neues Kennwort</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword2 ? 'text' : 'password'}
                        value={newPWone}
                        onChange={(e) => {
                            setNewPWone(e.target.value);
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    <br/><br/>
                    <InputLabel htmlFor="outlined-adornment-password">Neues Kennwort wiederholen</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword3 ? 'text' : 'password'}
                        value={newPWtwo}
                        onChange={(e) => {
                            setNewPWtwo(e.target.value);
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword3}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword3 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    {CheckPW(newPWone, newPWtwo) ? (<Alert severity="error">Neues Kennwort stimmt nicht überein, oder entspricht nicht den Anforderungen!</Alert>) : (<Alert severity="success">Neues Kennwort entspricht den Anforderungen.</Alert>)}

                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleChanchePasswort} disabled={CheckPW(newPWone, newPWtwo)}>
                        Ändern
                    </Button> 
                    <Button onClick={handleClose} autoFocus>
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alertsnack onClose={handleCloseSnack} severity={notifyColor} sx={{ width: '100%' }}>
                        {notifyMessage}
                    </Alertsnack>
                </Snackbar>
        </>
    )
}
