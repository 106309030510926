import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, admin, logout } from './listItems';
import logowhite from '../images/Logo_white.png';
import {useRef} from 'react';
import {useEffect, useState} from 'react';
import TemporaryDrawer from './tempSidebar'

import './homecss.css';
import zIndex from '@mui/material/styles/zIndex';

const drawerWidth = 240;

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
          Livio Kneubühler
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(0),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
          },
        }),
      },
    }),
  );

  const mdTheme = createTheme();


  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

function Sidebar(props) {
    

    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => {
      setOpen(!open);
    };

    let adminlist;
    if (localStorage.getItem('Rolle') == 10) {
      adminlist = admin;
    }

    // Validate Screen Width 
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    // Define Max Screensize for Mobile
    const maxMobileSize = 799;

    if (windowSize.innerWidth > maxMobileSize ){

      return (
        <div>
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h5"
                  color="inherit"
                >     
                    {props.title}
                </Typography>
    
              </Toolbar>
            </AppBar>
            <Drawer 
              variant="permanent"
              open={open}
            >
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                {mainListItems}
                {adminlist}
                <Divider sx={{ mb: 0}} />
                {logout}             
              </List>
              <Link href="https://forms.gle/m54MiPtkA8ZqDFgP8" target="_blank" color="inherit" underline="hover" sx={{pl: 2, pb: 1}}>Fehler melden</Link>
              <Link href="https://forms.gle/tC3SaYYVgSS3W8y99" target="_blank" color="inherit" underline="hover" sx={{pl: 2}}>Feedback geben</Link>
            </Drawer>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                height: '100vh',
                width: '100%',
                overflow: 'auto',
              }}
            >
              
              <Toolbar />
              {props.content}
              <Container>
                
                <Copyright sx={{ pt: 4 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
        </div>
      )

    } else {
      // Mobile
      return (
        <>
          <div>
            <ThemeProvider theme={mdTheme}>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      height: '100vh',
                      width: '100%',
                      overflow: 'auto',
                    }}
                    role="presentation"
                  >
                    
                    <Toolbar />
                    
                    {props.content}
                    <TemporaryDrawer title={props.title} />
                    
                    <Container>
                      
                      <Copyright sx={{ pt: 4 }} />
                      
                    </Container>
                  </Box>
              </Box>
            </ThemeProvider>
          </div>
        </>
      )
    }

}

export default Sidebar;