import React from 'react';

const WYSIWYGRenderTextSanitizedHTML = (props) => {
    const { html } = props;
    const text = document.createElement("div");
    text.innerHTML = html;
    let plainText = text.innerText.replace(/\n/g, ' '); // Replace newline characters with spaces

    if (plainText.length > 80) {
        plainText = plainText.slice(0, 77) + '...'; // Truncate the text and add "..."
    }

    return (
        <>{plainText}</>
    );
};

export default WYSIWYGRenderTextSanitizedHTML;
