import React from 'react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Axois from 'axios';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import AddProgrammButton from './components/AddProgrammButton';
import WYSIWYGRenderTextSanitizedHTML from './components/WYSIWYGRenderTextSanitizedHTML';
import "./programms.css";

function Programme() {

    const [programmData, setProgrammData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getprogramms', {
          
        }, 
        {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
        }).then((response) => {
            setProgrammData(response.data)
        });
    }, []);


    const handleRowClick = (StichID) => {
        navigate(`/programme/${StichID}`);
      }

  return (
    <>
    <Container width="100%">
        <h1>Programme & Stiche verwalten</h1>
        
        <AddProgrammButton/>

        <Box sx={{
          paddingTop: 5,
        }}>

        <TableContainer className='ProgTable'>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>StichID (Referenznummer)</TableCell>
                  <TableCell>Stichname</TableCell>
                  <TableCell>Beschreibung</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programmData.map((program) => (
                  <TableRow
                    key={program.StichID}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className='HoverRow'
                    onClick={() => handleRowClick(program.StichID)}
                  >
                    <TableCell component="th" scope="row">
                      {program.reference_number}
                    </TableCell>
                    <TableCell>{program.StichName}</TableCell>
                    <TableCell><WYSIWYGRenderTextSanitizedHTML html={program.stich_description}/></TableCell>
                    <TableCell align="right">
                      <EditIcon sx={{ color: "#707070"}}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
    </Container>
    </>
    
  )
}

export default Programme;