import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';


/* Import Custom CSS*/
import './StatsPerformance.css';

/* Formatting the Date */
function DateFormatter(fdate){
  let sdate = new Date(fdate.substring(0,10));
          
  var options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric"
  }

  return(sdate.toLocaleDateString("de", options));
}

export default function StatsPerformance(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [loadingstate, setLoadingState] = useState(true);
  const [labels, setLabels] = useState(["January", "February", "March", "April", "May", "June"]);
  const [data, setData] = useState([0, 10, 50, 2, 20, 30, 45, 45])


  useEffect(() => {
    Axios.post(
      process.env.REACT_APP_BACKEND_URL + 'getperformancestats',
      {
        year: props.year,
      },
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      }
    )
      .then((response) => {
        if (response.data) {
          let TmpLabelsArray = []
          let TmpDataArray = []
          for (let i = 0; i < response.data.length; i++) {
            let FriendlyDate = DateFormatter(response.data[i].Datum)
            TmpLabelsArray.push(FriendlyDate)
          }
          for (let i = 0; i < response.data.length; i++) {
            TmpDataArray.push(response.data[i].AVG)
          }
          setLabels(TmpLabelsArray);
          setData(TmpDataArray);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const ChartData = {
    labels: labels,
    datasets: [
      {
        label: "Performance " + props.year,
        borderColor: "rgb(25, 118, 210)",
        data: data,
      },
    ],
  };

  const ChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100
      }
    }
  };

  return (
    <>
      <h2>🚀 Performance
        <IconButton 
          size="small" 
          sx={{ mt: 0, pt: 0}}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <HelpIcon color="primary" size="inherit"/>
        </IconButton>
      </h2>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2" sx={{ m: 1}}>
        Die Performance Statistik zeigt dir den Druchschnitt der Hunderterwertung aller Schüsse am jeweiligen Tag an.
        </Typography>
      </Popover>
      <div className='ChartDiv'>
        <Line data={ChartData} options={ChartOptions}/>
      </div>
    </>
  );
}
