import React from 'react'
import Axois from 'axios';
import {useState} from 'react';
import { useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadHistoryStatusCode from './UploadHistoryStatusCode';
import UploadHistoryOptions from './UploadHistoryOptions'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function createData(FileID, Created, LastUpdated, OriginalFileName, FileSize, StatusCode) {
    return { FileID, Created, LastUpdated, OriginalFileName, FileSize, StatusCode };
  }

function DateFormatter(fdate){
    let sdate = new Date(fdate);

    return(sdate.toLocaleString("de"));
}
  
export default function UploadHistory() {

    const [rows, setRow] = useState([]);
    const [isDataHere, setIsDataHere] = useState(false);

    const [reload, setReload] = useState(1);

    useEffect(() => {
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getuploadhistory', {

            }, 
            {
                headers: {
                "x-access-token": localStorage.getItem("token"),
                },
            }).then((response) => {
                if(!response.data.error) {
                    setIsDataHere(true);
                }
                let DataArr = [];
                for (var i=0; i < response.data.length; i++) {
                    DataArr.push(createData(response.data[i].FileID, response.data[i].Created, response.data[i].LastUpdated, response.data[i].OriginalFileName, response.data[i].FileSize, response.data[i].StatusCode))
                }
                setRow(DataArr);
            });
    }, [reload]);


  if (rows.length < 1) {
    return (
        <>
            <Box>
            <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                Keine Daten vorhanden. Lade zu erst Dateien hoch.<br/>
                <Button variant="outlined" size="medium" onClick={() => {
                    setReload(reload + 1);
                }} sx={{ marginTop: 1.25 }}>
                    <RefreshIcon sx={{ marginRight: 1.25 }}/> Aktualisieren
                </Button>
            </Alert>
            </Box>
        </>
    )
  } else {
    return (
        <>
            <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="Upload History">
                  <TableHead>
                    <TableRow>
                      <TableCell>Hochgeladen am</TableCell>
                      <TableCell>Datei Name</TableCell>
                      <TableCell>Dateigrösse</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        <Tooltip title="Aktualisieren" placement="top" arrow>
                            <Button variant="text" size="medium" onClick={() => {
                                setReload(reload + 1);
                            }}>
                                <RefreshIcon/> 
                            </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.FileID}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {DateFormatter(row.Created)}
                        </TableCell>
                        <TableCell>{row.OriginalFileName}</TableCell>
                        <TableCell>{(row.FileSize / 1024).toFixed(1)} KB</TableCell>
                        <TableCell><UploadHistoryStatusCode code={row.StatusCode}/></TableCell>
                        <TableCell>
                            <UploadHistoryOptions File={row}/>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
    
            </Box>
        </>
      )
  }
}
