import './App.css';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Sidebar from './pages/Sidebar';
import Home from './pages/Home';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Resultate from './pages/Resultate';
import UploadData from './pages/UploadData';
import ProtectedRoutes from './pages/ProtectedRoutes';
import ProtectedAdminRoutes from './pages/ProtectedAdminRoutes';
import Axois from 'axios';
import LogOut from './pages/Logout'
import UserSettings from './pages/UserSettings';
import UserManagement from './pages/UserManagement';
import Result from './pages/detailResult';
import StatsBasic from './pages/StatsPage'
import Programme from './pages/programms'
import EdidProgramme from './pages/edidprogramm'

function App() {

  Axois.defaults.withCredentials = true;


  return (
    <div className="App">
        <Router>
          <Routes>
            <Route element={ <ProtectedRoutes/> }>
              <Route path="/" element={<Sidebar content={<Home />} title="Startseite"/>} />
              <Route path="/registration" element={<Sidebar content={<Registration />} title="Konto Erstellen" />} />
              <Route path="/resultate" element={<Sidebar content={<Resultate />} title="Resultate" />} />
              <Route path="/Statistiken" element={<Sidebar content={<StatsBasic />} title="Deine Statistiken" />} />
              <Route path="/resultat/:schiessenid" element={<Sidebar content={<Result />} title="Resultate Details" />} />
              <Route path="/einstellungen" element={<Sidebar content={<UserSettings />} title="Einstellungen" />} />

              <Route element={ <ProtectedAdminRoutes/> }>
                <Route path="/uploaddata" element={<Sidebar content={<UploadData />} title="Daten Hochladen" />} />
                <Route path="/usermanagement" element={<Sidebar content={<UserManagement />} title="Benutzer Verwalten" />} />
                <Route path="/programme" element={<Sidebar content={<Programme />} title="Programme" />} />
                <Route path="/programme/:stichid" element={<Sidebar content={<EdidProgramme />} title="Programm Bearbeiten" />} />
              </Route>

            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<LogOut />} />
          </Routes>
        </Router>
    </div>
    
  );
}

export default App;