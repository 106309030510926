import React from 'react'
import Container from '@mui/material/Container';
import Results from './results';

import './homecss.css';

import DevWarning from './components/DevWarning'

function Home() {
    
  

  return (
    <Container width="100%">
      <br/>
      <h1>Hallo {localStorage.getItem("Vorname") + " " + localStorage.getItem("Nachname")}!</h1>
    
      <Results/>

      
    </Container>
  )
}

export default Home;
