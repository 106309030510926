import React from 'react';
import { useEffect } from 'react';
/* Custom CSS*/
import './target.css';

function TargetB(props) {

    useEffect(()=>{ 

        const drawtargetB = (() => {
            var canvas = document.getElementById('targetB');
            if (canvas.getContext) {
                var ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                var X = canvas.width / 2;
                var Y = canvas.height / 2;
                 
                // White Circle in Background
                ctx.fillStyle = "#ffffff";
                ctx.beginPath();
                var RR = canvas.width / 2;
                ctx.arc(X, Y, RR, 0, 2 * Math.PI, false);
                ctx.fill();

                // Green Structure
                ctx.beginPath();

                // Bottom Left
                ctx.moveTo(canvas.width / 20 * 5.5, canvas.width / 20 * 14);
                // Bottem Right
                ctx.lineTo(canvas.width / 20 * 14.5, canvas.width / 20 * 14);
                // Top Right
                ctx.lineTo(canvas.width / 20 * 14.5, canvas.width / 20 * 6.5);
                // Top Line Right
                ctx.lineTo(canvas.width / 20 * 13, canvas.width / 20 * 6);
                // Top middle Right
                ctx.lineTo(canvas.width / 20 * 12, canvas.width / 20 * 5.75);
                // Top TOP middle Right
                ctx.lineTo(canvas.width / 20 * 12, canvas.width / 20 * 4)
                // Top TOP middle Left
                ctx.lineTo(canvas.width / 20 * 8, canvas.width / 20 * 4);
                // Top middle Left
                ctx.lineTo(canvas.width / 20 * 8, canvas.width / 20 * 5.75);
                // Top Line Left
                ctx.lineTo(canvas.width / 20 * 7, canvas.width / 20 * 6);
                // Top Left
                ctx.lineTo(canvas.width / 20 * 5.5, canvas.width / 20 * 6.5);
                ctx.closePath();
                ctx.fillStyle = "#386352";
                ctx.fill();

                // Draw + in Center
                ctx.beginPath();
                ctx.moveTo(canvas.width / 2 + 6, canvas.width / 2);
                ctx.lineTo(canvas.width / 2 - 6, canvas.width / 2);
                ctx.moveTo(canvas.width / 2, canvas.width / 2 + 6);
                ctx.lineTo(canvas.width / 2, canvas.width / 2 - 6);
                ctx.closePath();
                ctx.lineWidth = 1;
                ctx.strokeStyle = "#FFFFFF";
                ctx.stroke();

                // Draw Numbers
                if (canvas.width >= 1000) {
                    ctx.font = '90px Roboto';
                } else if (canvas.width >= 750){
                    ctx.font = '70px Roboto';
                } else if (canvas.width >= 500) {
                    ctx.font = '50px Roboto';
                } else if (canvas.width >= 250){
                    ctx.font = '30px Roboto';
                }else {
                    ctx.font = '15px Roboto';
                }
                
                ctx.textAlign = "center";
                ctx.fillStyle = '#FFFFFF';
                ctx.fillText('4', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 0.35));
                ctx.fillText('3', canvas.width / 2, canvas.width / 2 - (canvas.width / 20 * 3.5));


                ctx.save();
                ctx.translate( canvas.width / 2, canvas.height / 2 );
                ctx.rotate( Math.PI / 2 );
                ctx.fillText( "3", 0, (canvas.width / 2) / 10 * -2.5 );
                ctx.fillStyle = '#386352';
                ctx.fillText( "2", 0, (canvas.width / 2) / 10 * -5 );
                ctx.fillText( "1", 0, (canvas.width / 2) / 10 * -8 );
                ctx.restore();
                ctx.save();
                ctx.translate( canvas.width / 2, canvas.height / 2 );
                ctx.rotate( Math.PI / -2 );
                ctx.fillStyle = '#FFFFFF';
                ctx.fillText( "3", 0, (canvas.width / 2) / 10 * -2.5 );
                ctx.fillStyle = '#386352';
                ctx.fillText( "2", 0, (canvas.width / 2) / 10 * -5 );
                ctx.fillText( "1", 0, (canvas.width / 2) / 10 * -8 );
                ctx.restore();

                // Draw Circles
                for (let R = 1; R < 10; R = R + 1) {
                    ctx.beginPath();
                    var RR = canvas.width / 20 * R;
                    ctx.arc(X, Y, RR, 0, 2 * Math.PI, false);
                    ctx.lineWidth = 0.5;
                    ctx.strokeStyle = '#a3a3a3';
                    ctx.stroke();
                }

                var R = (canvas.width / 2);
                ctx.beginPath();
                ctx.arc(X, Y, R, 0, 2 * Math.PI, false);
                ctx.lineWidth = 1.5;
                ctx.strokeStyle = '#000000';
                ctx.stroke();

                var R = (canvas.width / 20);
                var R = R * 7;
                ctx.beginPath();
                ctx.arc(X, Y, R, 0, 2 * Math.PI, false);
                ctx.lineWidth = 1.5;
                ctx.strokeStyle = '#000000';
                ctx.stroke();

                var R = (canvas.width / 20);
                var R = R * 2;
                ctx.beginPath();
                ctx.arc(X, Y, R, 0, 2 * Math.PI, false);
                ctx.lineWidth = 1.5;
                ctx.strokeStyle = '#FFFFFF';
                ctx.stroke();                
            
            
            }
        });
        drawtargetB();

        const drawshotB4 = ((X, Y) => {
            var canvas = document.getElementById('targetB');
            if (canvas.getContext) {
                var ctx = canvas.getContext('2d');
                ctx.save();
                ctx.translate( canvas.width / 2, canvas.height / 2 );
                
                if (props.hover == i) {
                    ctx.fillStyle = "#eb4034";
                } else {
                    ctx.fillStyle = "#347deb";
                }
                var ConX = (canvas.width / 1000) * X;
                var ConY = (canvas.width / 1000) * Y;
                ctx.beginPath();
                ctx.arc(ConY, ConX * -1, 5, 0, 2 * Math.PI, false);
                ctx.fill();
                ctx.restore();
            }
        });

        for(var i = 0; i < props.data.length; i++){
            if (props.data[i].Scheibe === "B4") {
                drawshotB4(props.data[i].Xcord , props.data[i].Ycord);
            }
        }

    });

  return (
    <div width="auto" height="auto" className='TargetDiv'>
        <canvas id="targetB" width="575x" height="575px" className='TargetCanvas'></canvas>
    </div>
  )
}

export default TargetB