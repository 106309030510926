import React from 'react'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Navigate } from 'react-router-dom'
import Axois from 'axios';
import {useState} from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import StatsBasic from './components/StatsBasic';
import { useNavigate } from 'react-router-dom';
import './result.css';

function DateFormatter(fdate){
    let sdate = new Date(fdate.substring(0,10));
            
    var options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "numeric"
    }

    return(sdate.toLocaleDateString("de", options));
}

function getTotal(schiessenid){
    Axois.post(process.env.REACT_APP_BACKEND_URL + 'gettotalres', {
        SiessenID: schiessenid,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
      },
      }).then((response) => {
        document.getElementById(schiessenid).innerHTML = response.data.toString();
        // return response.data.toString();
      });
      return (<Skeleton sx={{width: '2em'}}/>)
}

export default function Results() {

    const [data, setData] = useState([]);
    const [isDataHere, setIsDataHere] = useState(false);
    const [statsYear, setStatsYear] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        
        Axois.post(process.env.REACT_APP_BACKEND_URL + 'getResults', {
            last: 5,
          }, 
          {
              headers: {
                "x-access-token": localStorage.getItem("token"),
              },
          }).then((response) => {
                if(!response.data.error) {
                    setIsDataHere(true);
                }
            
                var currentstatsyear = new Date(response.data[0].Datum)
                setStatsYear(currentstatsyear.getFullYear())
                setData(response.data);

          });
      }, []);

      const handleRowClick = (siessenID) => {
        navigate(`/resultat/${siessenID}`);
      }

    if(isDataHere){
        return (
            <>
            <h2>Deine letzen Resultate</h2>
                <TableContainer
                sx={{ borderRadius: '3px'}}
                className='TableContainer'
                >
                    <Table aria-label="Deine Resultate">
                        <TableHead className={"TableHead"}>
                            <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Stich / Programm</TableCell>
                                <TableCell>Resultat</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.map((data) => (      
                                    <TableRow
                                        key={data.SiessenID}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => handleRowClick(data.SiessenID)}
                                        className={"HoverRow"}
                                    >
                                        <TableCell>{DateFormatter(data.Datum)}</TableCell>
                                        <TableCell>{data.StichName}</TableCell>
                                        <TableCell id={data.SiessenID}>{getTotal(data.SiessenID)}</TableCell>
                                        <TableCell>
                                            
                                                    <KeyboardArrowRightIcon sx={{ fontSize: 25, color: '#1976d2' }} className={"HoverIcon"}/>
                                              
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <Link to="/resultate/">
                    <Button variant="text">
                        Weitere Resultate ansehen <KeyboardArrowRightIcon sx={{ fontSize: 25 }}/>
                    </Button>
                </Link>
                <br/><br/>
                <h2>Deine Statistiken von {statsYear}</h2>
                <StatsBasic year={statsYear}/>
                <br/>
                <Link to="/Statistiken">
                    <Button variant="text">
                        Weitere Statistiken ansehen <KeyboardArrowRightIcon sx={{ fontSize: 25 }}/>
                    </Button>
                </Link>
            </>
          )

    } else {
        return (
            <>
                <h2>Deine letzen Resultate</h2>
                <Alert icon={<FeedbackIcon fontSize="inherit" />} severity="warning">
                Keine Resultate gefunden...
                </Alert>
            </>
        )
    
        
    }

}
