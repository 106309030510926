import React from 'react'
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Axois from 'axios';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import "./UserManagement.css";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';


function CheckPW(PW1, PW2){
  let PWStrenght = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  if (PW1 == PW2){
      if (PWStrenght.test(PW2)){
          return false
      }
      else {
          return true
      }
  } else {
      return true
  }
}

function UserManagement() {

  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [wantToSetNewPassword, setWantToSetNewPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDelUserPromt, setOpenDelUserPromt] = React.useState(false);
  const [openCreateUserPromt, setOpenCreateUserPromt] = React.useState(false);
  const [newPWone, setNewPWone] = React.useState('');
  const [newPWtwo, setNewPWtwo] = React.useState('');
  const [newFirstName, setNewFirstName] = React.useState('');
  const [newSecondName, setNewSecondName] = React.useState('');
  const [newMailAdress, setNewMailAdress] = React.useState('');
  const [opensnack, setOpensnack] = React.useState(false);
  const [notifyMessage, setnotifyMessage] = React.useState('');
  const [notifyColor, setnotifyColor] = React.useState('');
  const [updateUserData, setUpdateUserData] = React.useState(0);
  const [newUserLizenznummer, setNewUserLizenznummer] = React.useState();


    useEffect(() => {
      Axois.post(process.env.REACT_APP_BACKEND_URL + 'getusers', {
      }, 
      {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
      }).then((response) => {
          setUserData(response.data)
      });
  }, [updateUserData]);


  const handleRowClick = (UserID) => {
    for(var index = 0; index < userData.length; ++index) {
      var entry = userData[index]
      if (entry.UserID == UserID) {
        setSelectedUser(entry)
        setNewFirstName(entry.Vorname)
        setNewSecondName(entry.Nachname)
        setNewMailAdress(entry.E_Mail_Adress)
        handleClickOpen();
      }
    }   
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setWantToSetNewPassword(false)
    setNewPWone('');
    setNewPWtwo('');
  };

  const handlenewPassword = () => {
    if (wantToSetNewPassword) {
      setWantToSetNewPassword(false);
      setNewPWone('');
      setNewPWtwo('');
    } else {
      setWantToSetNewPassword(true);
    }
    
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openInfo = Boolean(anchorEl);

// Snackbar for API Response  
const handleCloseSnack = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpensnack(false);
};

const Alertsnack = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

  // API Request to save Changes
  const handleSaveUserChanges = () => {
    Axois.post(process.env.REACT_APP_BACKEND_URL + 'modifyuser', {
        UserID: selectedUser.UserID,
        wantToSetNewPassword: wantToSetNewPassword,
        NewPW: newPWone,
        newMailAdress: newMailAdress,
        newFirstName: newFirstName,
        newSecondName: newSecondName
    }, 
    {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
    }).then((response) => {
      setnotifyMessage(response.data.success);
      setnotifyColor("success")
      setOpensnack(true);
      setUpdateUserData(updateUserData + 1);
      handleClose();
    }).catch((error) => {
      setnotifyMessage(error.response.data.error)
      setnotifyColor("error")
      setOpensnack(true);
    });
  }

  // API Request to Delete User
  const deleteUser = () => {
    setOpenDelUserPromt(true)
  }

  const defdeleteUser = () => {
    console.log("User 2 Delete: (Definitly)" + selectedUser.UserID)

    Axois.post(process.env.REACT_APP_BACKEND_URL + 'deleteuser', {
      UserID: selectedUser.UserID,
    }, 
    {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
    }).then((response) => {
      setnotifyMessage(response.data.success);
      setnotifyColor("success")
      setOpensnack(true);
      setUpdateUserData(updateUserData + 1);
      handleClose();
      setOpenDelUserPromt(false)
    }).catch((error) => {
      setnotifyMessage(error.response.data.error)
      setnotifyColor("error")
      setOpensnack(true);
      setOpenDelUserPromt(false)
    });

  }

  const handleCloseDelUserPromt = () => {
    setOpenDelUserPromt(false)
  }

  const handleOpenCreateUserPromt = () => {
    setOpenCreateUserPromt(true)
  }

  const handleCloseCreateUserPromt = () => {
    setOpenCreateUserPromt(false)
  }

  const defCreateUser = () => {
    Axois.post(process.env.REACT_APP_BACKEND_URL + 'createuser', {
      newUserID: newUserLizenznummer,
    }, 
    {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
    }).then((response) => {
      setnotifyMessage(response.data.success);
      setnotifyColor("success");
      setOpensnack(true);
      setUpdateUserData(updateUserData + 1);
      handleCloseCreateUserPromt();

      var NewUser = {};
      NewUser.UserID = newUserLizenznummer;
      NewUser.Vorname = "";
      NewUser.Nachname = "";
      setSelectedUser(NewUser)
      setNewFirstName("")
      setNewSecondName("")
      setNewMailAdress("")
      handleClickOpen();
      
    }).catch((error) => {
      setnotifyMessage(error.response.data.error);
      setnotifyColor("error");
      setOpensnack(true);
    });
  }


  return (
    <>
    <Container width="100%">
        <h1>Benutzerverwaltung</h1>
        <Box >
          <Button variant="contained" size="medium" onClick={handleOpenCreateUserPromt}>
            Benutzer Hinzufügen
          </Button>
        </Box>
        <Box sx={{
          paddingTop: 5,
        }}>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Lizenznummer</TableCell>
                  <TableCell>Vorname</TableCell>
                  <TableCell>Nachname</TableCell>
                  <TableCell>E-Mail Adresse</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user) => (
                  <TableRow
                    key={user.UserID}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className='HoverRow'
                    onClick={() => handleRowClick(user.UserID)}
                  >
                    <TableCell component="th" scope="row">
                      {user.UserID}
                    </TableCell>
                    <TableCell>{user.Vorname}</TableCell>
                    <TableCell>{user.Nachname}</TableCell>
                    <TableCell>{user.E_Mail_Adress}</TableCell>
                    <TableCell align="right">
                      <EditIcon sx={{ color: "#707070"}}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
    </Container>

    <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle id="alert-dialog-title">
                    Bearbeiten: <b>{selectedUser.Vorname + ' ' + selectedUser.Nachname}</b> / UserID: {selectedUser.UserID}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <TextField 
                                id="standard-start-adornment" 
                                label="Vorname" 
                                variant="outlined" 
                                value={newFirstName}
                                
                                onChange={(e) => {
                                  setNewFirstName(e.target.value)
                                }}
                                sx={{ m: 1, width: '25ch' }}
                            /> <br/><br/>
                            <TextField 
                                id="standard-start-adornment" 
                                label="Nachname" 
                                variant="outlined" 
                                value={newSecondName}
                                
                                onChange={(e) => {
                                  setNewSecondName(e.target.value)
                                }}
                                sx={{ m: 1, width: '25ch' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <TextField 
                                id="standard-start-adornment" 
                                label="E-Mail Adresse" 
                                variant="outlined" 
                                value={newMailAdress}
                                
                                onChange={(e) => {
                                  setNewMailAdress(e.target.value)
                                }}
                                sx={{ m: 1, width: '52ch' }}
                            /> 
                        </Box><br/>
                        <Divider/>
                        
                        {wantToSetNewPassword ? (
                        <>
                        <br/>
                        <strong>Neues Kennwort Festlegen</strong>
                        <IconButton
                          aria-owns={openInfo ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                          sx={{ mt: 0, pt: 0}}
                          size="small" 
                        >
                          <HelpIcon color="primary"/>
                        </IconButton>
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openInfo}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography sx={{ p: 1 }}>
                          Das Kennwort muss folgende Anforderungen erfüllen: <br/>
                        - Mindestens <b>8 Zeichen</b><br/>
                        - Mindestens ein <b>Buchstabe von 'a' bis 'Z'</b> enthalten<br/>
                        - Mindestens eine <b>Zahl von 0 bis 9</b> enthalten<br/>
                        - Mindestens ein <b>Sonderzeichen +"*%&/()=?!$</b> einthalten<br/>
                          </Typography>
                        </Popover>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextField 
                                id="standard-start-adornment" 
                                label="Kennwort" 
                                variant="outlined" 
                                type='password'
                                value={newPWone}
                                onChange={(e) => {
                                  setNewPWone(e.target.value);
                              }}
                                sx={{ m: 1, width: '52ch' }}
                            />
                          <TextField 
                                id="standard-start-adornment" 
                                label="Kennwort wiederholen" 
                                variant="outlined" 
                                type='password'
                                value={newPWtwo}
                                onChange={(e) => {
                                  setNewPWtwo(e.target.value);
                              }}
                                sx={{ m: 1, width: '52ch' }}
                            />
                            {CheckPW(newPWone, newPWtwo) ? (
                              <Alert severity="error" sx={{ m: 1, width: '52ch' }} >
                                Neues Kennwort stimmt nicht überein oder entspricht nicht den Anforderungen!
                              </Alert>
                            ) : (
                              <Alert severity="success" sx={{ m: 1, width: '52ch' }} >
                                Neues Kennwort entspricht den Anforderungen.
                              </Alert>
                            )}
                        </Box>
                        <Button onClick={handlenewPassword} color="inherit"><ClearIcon sx={{ mr: 0.5}} /> Kennwort nicht Zurücksetzen</Button>
                        </>
                        ) : (
                        <>
                          <Button onClick={handlenewPassword} color="inherit"><VpnKeyIcon sx={{ mr: 0.5}} /> Kennwort Zurücksetzen</Button>
                        </>
                        ) }
                        
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    {wantToSetNewPassword ? (
                      <Button onClick={handleSaveUserChanges} color="primary" disabled={CheckPW(newPWone, newPWtwo)}>  
                        <CheckIcon sx={{ mr: 0.5}}/> Speichern
                      </Button>
                    ) : (
                      <Button onClick={handleSaveUserChanges} color="primary">  
                        <CheckIcon sx={{ mr: 0.5}}/> Speichern
                      </Button>
                    )}

                    <Button onClick={deleteUser} color="error">
                      <DeleteIcon sx={{ mr: 0.5}}/> Löschen
                    </Button> 
                    <Button onClick={handleClose} color="inherit">
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                  open={openDelUserPromt}
                  onClose={handleCloseDelUserPromt}

                >
                  <Typography sx={{ p: 3}}>
                    
                    Möchtest du <b> {selectedUser.Vorname + ' ' + selectedUser.Nachname}</b> (ID: {selectedUser.UserID}) wirklich Löschen?
                    </Typography>
                   
                <DialogActions>
                    <Button onClick={defdeleteUser} color="error">
                    <DeleteIcon sx={{ mr: 0.5}}/> Ja
                    </Button> 
                    <Button onClick={handleCloseDelUserPromt} color="inherit">
                        Nein
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                  open={openCreateUserPromt}
                  onClose={handleCloseCreateUserPromt}

                >
                  <DialogTitle id="alert-dialog-title">
                    Benutzer Hinzufügen
                  </DialogTitle>
                  
                    <TextField 
                      id="standard-start-adornment" 
                      label="Lizenznummer" 
                      variant="outlined" 
                      value={newUserLizenznummer}
                      type='number'
                      onChange={(e) => {
                        setNewUserLizenznummer(e.target.value)
                      }}
                      sx={{ m: 2.5, width: '50ch' }}
                  />
                   
                <DialogActions>
                    <Button onClick={defCreateUser} color="primary">
                    <AddIcon sx={{ mb: 0.3 }}/> Erstellen
                    </Button> 
                    <Button onClick={handleCloseCreateUserPromt} color="inherit">
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>
        <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alertsnack onClose={handleCloseSnack} severity={notifyColor} sx={{ width: '100%' }}>
              {notifyMessage}
          </Alertsnack>
        </Snackbar>

    </>
  )
}

export default UserManagement